import React from "react";

const ICard = ({ tag, jsx, onPress }) => {
  return (
    <button onClick={onPress} className="">
      <div className="relative bg-white px-6 p-2 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        {jsx}
      </div>
      <p className="  text-center  font-semibold  ">{tag}</p>
    </button>
  );
};

export default ICard;
