import React from "react";
import ICard from "./ICard";
import logo from "../assets/logo.png";
import { useParams } from "react-router-dom";

const BCard = () => {
  const { id } = useParams();

  const downloadTxtFile = (vcfText) => {
    const element = document.createElement("a");
    const file = new Blob([vcfText], { type: "text/vcf" });
    element.href = URL.createObjectURL(file);
    element.download = "kulsha_contact.vcf";
    document.body.appendChild(element);
    element.click();
  };

  const CreateVCard = () => {
    const vcard = `BEGIN:VCARD\nVERSION:3.0\nN:${user[id].lname};${user[id].fname}\nFN:${user[id].fname} ${user[id].lname}\nADR;TYPE=work:;;;Manama;;Bahrain Bay;Bahrain\nTEL;TYPE=home:${user[id].phone}\nTEL;TYPE=work:+97313355111\nEMAIL;TYPE=internet,work:${user[id].email}\nURL;TYPE=work:kulsha.com\nORG:${user[id].fname}\nTITLE:${user[id].position}\nsocialProfile;type=facebook:http://www.facebook.com/kulsha.bh\nsocialProfile;type=twitter:http://www.twitter.com/kulshabh\n album;type=photo::\nEND:VCARD`;

    return vcard;
  };

  const social = [
    {
      tag: "Save Contact",
      jsx: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-full h-full"
        >
          {" "}
          <path
            fillRule="evenodd"
            d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            clipRule="evenodd"
          />{" "}
        </svg>
      ),
      onPress: () => downloadTxtFile(CreateVCard()),
    },
    {
      tag: "Call",
      jsx: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-full h-full"
        >
          <path
            fillRule="evenodd"
            d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
            clipRule="evenodd"
          />
        </svg>
      ),
      onPress: () => {
        window.open(`tel:${user[id].phone}`);
      },
    },
    {
      tag: "Email",
      jsx: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-full h-full"
        >
          <path
            fillRule="evenodd"
            d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z"
            clipRule="evenodd"
          />
        </svg>
      ),
      onPress: () => {
        window.open(`mailto:${user[id].email}`);
      },
    },
    {
      tag: "Maps",
      jsx: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-full h-full"
        >
          <path
            fillRule="evenodd"
            d="M8.161 2.58a1.875 1.875 0 011.678 0l4.993 2.498c.106.052.23.052.336 0l3.869-1.935A1.875 1.875 0 0121.75 4.82v12.485c0 .71-.401 1.36-1.037 1.677l-4.875 2.437a1.875 1.875 0 01-1.676 0l-4.994-2.497a.375.375 0 00-.336 0l-3.868 1.935A1.875 1.875 0 012.25 19.18V6.695c0-.71.401-1.36 1.036-1.677l4.875-2.437zM9 6a.75.75 0 01.75.75V15a.75.75 0 01-1.5 0V6.75A.75.75 0 019 6zm6.75 3a.75.75 0 00-1.5 0v8.25a.75.75 0 001.5 0V9z"
            clipRule="evenodd"
          />
        </svg>
      ),
      onPress: () => {
        window.location.replace(
          "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt"
        );
      },
    },
  ];

  const user = [
    {
      id: "1",
      fname: "Sarah",
      lname: "Mae",
      position: "Operations Executive",
      phone: "+97338380059",
      email: "sarah@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "2",
      fname: "Vimal",
      lname: "Soni",
      position: "Sales & Logistics Manager",
      phone: "+97336485995",
      
      email: "vimal@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "3",
      fname: "Reshibha ",
      lname: "Basdin",
      position: "Executive Assistant",
      phone: "+97339821578",
      email: "admin@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "4",
      fname: "Irfan",
      lname: "Haji",
      position: "CEO",
      phone: "+97313355111",
      email: "info@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "5",
      fname: "Imran",
      lname: "Haji",
      position: "Logisitcs Director",
      phone: "+97313355111",
      email: "imran@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "6",
      fname: "Isa",
      lname: "Haji",
      position: "CTO",
      phone: "+97313355111",
      email: "isa@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
    {
      id: "7",
      fname: "Niyamath",
      lname: "Husain Syed",
      position: "Customer Service Executive",
      phone: "+97338802380",
      email: "syed@kulsha.com",
      map: "https://goo.gl/maps/GpyNrAH3xEtccKak8?coh=178571&entry=tt",
    },
  ];

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
      <div className="relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
        <div className="mx-auto  max-w-md">
          <img
            src={logo}
            className="w-full h-48 rounded-xl"
            alt="Kulsha Logo"
          />
          <div className="divide-y mb-3 text-center divide-gray-300/50">
            <div className="pt-8  font-semibold leading-7 text-2xl ">
              <p className="text-gray-700">
                {user[id].fname} {user[id].lname}
              </p>
              <p>
                <p className="text-sky-500 text-lg mb-2 hover:text-sky-600">
                  {user[id].position}
                </p>
              </p>
            </div>
            <div className="divide-y divide-gray-300/50">
              <div className="text-center space-y-6">
                <h1 className="text-gray-900 font-bold p-3">Contact</h1>
              </div>

              <div className="grid grid-cols-2 grid-rows-2 gap-5 p-2">
                {social.map((val, index) => {
                  return (
                    <ICard
                      key={index}
                      onPress={val.onPress}
                      jsx={val.jsx}
                      tag={val.tag}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BCard;
